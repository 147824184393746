body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




/* Global */

body {
  font-size: 16px;
}

a {
  text-decoration: none;
}

.vertical-middle {
  vertical-align: middle;
}



.mdl-error-message {
  width: 100%;
  max-width: 100%;
  background-color: #fff9c4;
  padding: 16px 8px;
  border-radius: 2px;
  color: #000;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}


.general-spin::after {
  display: none;
}

.state-list-loading .general-spin::after {
  content: '';
  display: block;
  position: absolute;
  top: 30vh;
  left: 45%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: general-spin-1 .8s linear infinite;
  animation: general-spin .8s linear infinite;
}


.state-list-loading {
  opacity: 0.5;
  position: relative;
}


@keyframes general-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Menu */

.logo-container {
  line-height: 70px !important;
}

.logo {
  color: #008bcb;
  font-size: 2em;
}

.logo-img {
  max-width: 220px;
  max-height: 40px;
}

.logout {
  display: inline-block;
  width: 135px;
}

.logout-icon {
  float: right;
}

.headerTwo-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: white;
  color: #2d353b;
}

.mdl-navigation__link {
  font-size: 18px;
  opacity: 1;
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link.disabled {
  color: rgba(155, 155, 155, 1);
}

.mdl-navigation__link-logout {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 16px 40px;
  margin: 0;
  display: block;
  bottom: 0;
  position: fixed;
}

.radio-block {
  display: block;
  margin-top: 10px;
}

/*Over-writing*/

.mdl-checkbox {
  height: auto;
}

.mdl-button {
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: #dd4b38;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  background-color: #dd4b38;
}


.app-main-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.MuiContainer-root.app-main-container {
  padding-left: 0;
  padding-right: 0;
}
.home-bg {
  background: #dae5ea;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 auto;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 100vh;
}

.home-content-container {
  margin-top: 20%;
  margin-left: 10%;
  margin-right: 10%;
}


/* General*/

.right {
  float: right;
}

.left {
  float: left;
}

.hide {
  display: none;
}

.border-radius {
  border-radius: 4px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-top {
  border-radius: 4px 4px;
}

.border-radius-bottom {
  border-radius: 0 0 4px 4px;
}

.fixed {
  position: fixed;
}

.border {
  border: solid 1px black;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.no-shadow {
  box-shadow: none !important;
}

.no-border {
  border: 0 !important;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-disc {
  list-style-type: none;
}

.li-clear {
  list-style-type: none;
}

.ul-clear {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.center {
  display: flex;
  align-items: center;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-right-40 {
  margin-right: 40 !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-30 {
  padding-bottom: 30 !important;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-left-3 {
  padding-left: 3%;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-left-60 {
  padding-left: 60px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.heigh-300 {
  height: 300px;
  overflow: scroll;
}

.scroll {
  overflow: scroll;
}

.line-separator {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

.line-separator-full {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

.line-separator:last-child {
  border-bottom: 0 solid #e0e0e0;
}

.active {
  color: #757575 !important;
  font-weight: 600;
}

.active:hover {
  background-color: text-light !important;
  color: #757575 !important;
}

.single-checkbox-container {
  padding: 12px 20px 12px 20px;
  border-bottom: 1px solid #e0e0e0;
  width: calc(100% - 40px);
  /* display: inline-block; */
}
.single-checkbox-container:last-child {
  border-bottom: 0 solid #e0e0e0;
}

.top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.account-menu-icon-wrap {
  flex-grow: 1;
}

.account-menu-icon {
  float: right;
}
.MuiIconButton-root.account-menu-icon {
  padding-left: 2rem;
  padding-right: 2rem;
}

.main-menu-icon {
  float: right;
}
.main-menu-item {
  color: #243074;
}
.main-menu-active {
  color: white;
}

.MuiButton-root.rounded-button {
  border-radius: 18px;
}



.priority-indicator-bg-1, 
.MuiChip-root.priority-indicator-bg-1,
.priority-indicator-bg-stat, 
.MuiChip-root.priority-indicator-bg-stat {
  background-color: red;
}
.priority-indicator-bg-2, 
.MuiChip-root.priority-indicator-bg-2,
.priority-indicator-bg-asap, 
.MuiChip-root.priority-indicator-bg-asap {
  background-color: orange;
}
.priority-indicator-bg-3, 
.MuiChip-root.priority-indicator-bg-3,
.priority-indicator-bg-urgent, 
.MuiChip-root.priority-indicator-bg-urgent {
  background-color: yellow;
}
.priority-indicator-bg-4, 
.MuiChip-root.priority-indicator-bg-4,
.priority-indicator-bg-routine, 
.MuiChip-root.priority-indicator-bg-routine {
  background-color: green;
}
.priority-indicator-text-4, 
.MuiChip-root.priority-indicator-text-4,
.priority-indicator-text-routine, 
.MuiChip-root.priority-indicator-text-routine {
  color: white;
}
.priority-indicator-text-3, 
.MuiChip-root.priority-indicator-text-3,
.priority-indicator-text-urgent, 
.MuiChip-root.priority-indicator-text-urgent {
  color: #333;
}
.priority-indicator-text-2, 
.MuiChip-root.priority-indicator-text-2,
.priority-indicator-text-asap, 
.MuiChip-root.priority-indicator-text-asap {
  color: white;
}
.priority-indicator-text-1, 
.MuiChip-root.priority-indicator-text-1,
.priority-indicator-text-stat, 
.MuiChip-root.priority-indicator-text-stat {
  color: white;
}


.priority-indicator-bg-passive-stat, 
.MuiChip-root.priority-indicator-bg-passive-stat {
  background-color:#c97777;
}

.priority-indicator-bg-passive-asap, 
.MuiChip-root.priority-indicator-bg-passive-asap {
  background-color: #f5d69e;
}
.priority-indicator-bg-passive-urgent, 
.MuiChip-root.priority-indicator-bg-passive-urgent {
  background-color: #efef94;
}
.priority-indicator-bg-passive-routine, 
.MuiChip-root.priority-indicator-bg-passive-routine {
  background-color: #a4cfa4;
}

.field-error .cell-label {
  color: rgb(211, 47, 47);
}

.field-error .field-error-text {
  color: rgb(211, 47, 47);
}



/* BadgeMenu styles */

.MuiGrid-container.badge-menu-container {
  width: calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.badge-menu-column {
  flex: 1 1 0px;
}

.badge-wrapper {
  cursor: pointer;
  margin: 5px;
}

.badge-wrapper:hover {
  background-color: rgb( 250, 250, 250 );
}

.badge-wrapper {
  padding: 12px;
  height: 65px;
  text-align: center;
}

.badge-wrapper .count {
  font-size: 19px;
  vertical-align: middle;
  font-weight: bold;
}
.badge-wrapper .count .MuiSvgIcon-root {
  vertical-align: top;
  line-height: 20px;
  display: none;
}

.icon-with-spacing .badge-wrapper .count .MuiSvgIcon-root {
  margin-right: 8px;
}

.badge-wrapper .label {
  text-align: center;
  width: 100%;
  font-size: 13px;
}

.badge-menu-column.active .badge-wrapper {
  background: #3f51b5;
  color: white;
}

.badge-menu-column.active .badge-wrapper .label {
  color: white;
}

@media screen and (min-width: 1000px) {
    
  .MuiGrid-container.badge-menu {
    width: calc(100% + 20px);
    margin-right: -10px;
    margin-left: -10px;
  }

  .badge-wrapper {
    padding: 20px;
    height: 80px;
  }
  .badge-wrapper .count {
    font-size: 27px;
  }
  .badge-wrapper .label {
    font-size: 16px;
  }
  .badge-wrapper {
    margin: 10px;
  }
  .badge-wrapper .count .MuiSvgIcon-root {
    display: inline-block;
  }
}

/* BadgeMenu styles end */


/* TicketsTable styles */

.tickets-table-top {
  text-align: right;
}

.tickets-table-top h3 {
  float: left;
}
.tickets-table-top p {
  float: right;
  display: block;
  line-height: 25px;
}

.tickets-table-header {
  background-color: rgb( 250, 250, 250 );
}
.tickets-table-row {
  cursor: pointer;
}
.tickets-table-row:hover {
  background-color: rgb( 250, 250, 250 );
}

.priority-indicator-1, .priority-indicator-stat {
  border-left: 12px solid red;
}
.priority-indicator-2, .priority-indicator-asap {
  border-left: 12px solid orange;
}
.priority-indicator-3, .priority-indicator-urgent {
  border-left: 12px solid yellow;
}
.priority-indicator-4, .priority-indicator-routine {
  border-left: 12px solid green;
}

/* TicketsTable styles end */

/* TicketModal and HealthTicket styles */
.modal-ticket {
  background-color: #fff;
  width: 85%;
  max-width: 85%;
  margin: 10vh auto 10vh auto;
  min-height: 60vh;
  background: white;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  position: relative;
  outline: none;
  padding: 30px;
}

.inline-ticket {
  min-height: 60vh;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  padding: 15px 30px 15px 30px;
  margin: 5px;
}

.modal-ticket h3 {
  margin-top: 0;
  font-weight: 600;
}

.health-ticket-head-container {
  margin-bottom: 15px;
}
.health-ticket-container .ticket-expected-solving-time, 
.health-ticket-container .ticket-arrival-date {
  line-height: 17px;
  font-size: 13px;
  vertical-align: middle;
}
.health-ticket-container .ticket-expected-solving-time a, 
.health-ticket-container .ticket-expected-solving-time svg, 
.health-ticket-container .ticket-expected-solving-time span, 
.health-ticket-container .ticket-expected-solving-time p
.health-ticket-container .ticket-arrival-date {
  vertical-align: middle;
}


.ticket-actions-col {
  padding-top: 5px;
  text-align: center;
}
.ticket-status-col {
  text-align: right;
  line-height: 38px;
  line-height: normal;
}

.ticket-body-right, .ticket-body-left {
}
.ticket-body-left {

}

@media screen and (min-width: 960px) {
  .ticket-body-right {
    padding-left: 30px;
  }
  .ticket-actions-col {
    text-align: right;
  }
}

.health-ticket-part {
  padding: 15px;
  margin-bottom: 30px;
}

.previous-tickets-table {
  border: 1px solid rgba(224, 224, 224, 1);
}



.health-ticket-container .ticket-notes {

}

.health-ticket-container .ticket-notes textarea {
  border-radius: 1px;
  border: 1px solid #dddd;
  width: 100%;
  min-height: 100px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
  outline: none;
  padding: 10px;
}
.health-ticket-container .MuiTableCell-head {
  line-height: 0.2rem;
}
.health-ticket-container .MuiTableCell-root {
  line-height: 0.2rem;
}

.preview-questionnaire-response .MuiTableCell-head {
  line-height: 19px;
  padding: 6px 12px;
}
.preview-questionnaire-response .MuiTableCell-root {
  line-height: 19px;
  padding: 6px 12px;
}


@media screen and (min-width: 960px) {
  .health-ticket-name-and-priority-container .details-right {
  }
  .health-ticket-details-container .priority-col {

  }
}

.health-ticket-details-container td {
  padding: 6px 3px;
  font-size: 14px;
  vertical-align: top;
}

.health-ticket-details-container td.vertical-middle {
  vertical-align: middle;
}

.health-ticket-details-container .details-left {
  
}

.health-ticket-details-container .cell-label {
  color: rgb(153, 153, 153);
  padding-left: 0;
}

.health-ticket-main .cell-label {
  width: 40%;
}
.health-ticket-main .cell-data {
  width: 60%;
}

.health-ticket-details-container .cell-label-forward {
  font-weight: bold;
  padding-left: 0;
}

.MuiChip-root.priority-1 {
  background:  red;
}
.MuiChip-root.priority-2 {
  background:  orange;
}
.MuiChip-root.priority-3 {
  background: yellow;
}
.MuiChip-root.priority-4 {
  background: green;
}

.communication-method-selector .MuiSelect-selectMenu {
  white-space: normal;
}
.communication-method-selector .MuiSelect-selectMenu .MuiChip-root {
  margin-right: 8px;
  margin-bottom: 8px;
}
.communication-method-selector .MuiSelect-selectMenu {
  padding: 8px 0 0 8px;
}


.MuiAutocomplete-popper {
}
.MuiAutocomplete-popper * {
  line-height: 21px; 
  font-size: 11px;
}
.MuiAutocomplete-popper .MuiListSubheader-gutters {
}
.MuiAutocomplete-popper .MuiAutocomplete-groupLabel {
  line-height: 21px;
  padding: 8px 8px 8px 16px;
  font-weight: bold;
}
.MuiAutocomplete-popper .MuiAutocomplete-groupUl {
}
.MuiAutocomplete-popper .MuiAutocomplete-option {
  padding: 8px 8px 8px 32px;
}
.MuiAutocomplete-popper .MuiAutocomplete-option span {
}
.MuiAutocomplete-popper li {
}
.MuiAutocomplete-popper .option-practicioner, 
.MuiAutocomplete-popper .MuiAutocomplete-groupUl li, 
.MuiAutocomplete-popper .MuiAutocomplete-groupUl li a {
  min-height: 21px;
  line-height: 21px;
}
.MuiAutocomplete-popper .delete-listitem {
  color: rgb(204, 48, 48);
}
.MuiAutocomplete-popper .edit-listitem, 
.MuiAutocomplete-popper .delete-listitem {
  display: none;
}
.MuiAutocomplete-popper .MuiAutocomplete-groupUl li:hover .edit-listitem, 
.MuiAutocomplete-popper .MuiAutocomplete-groupUl li:hover .delete-listitem {
  display: inline-block;
  margin-left: 5px;
  line-height: 15px; 
}
.MuiAutocomplete-popper .bold, 
.MuiAutocomplete-popper .bold {
  font-weight: bold;
}
.MuiAutocomplete-popper .not-bold {
  font-weight: normal;
}

.MuiAutocomplete-popper .greyed-out, 
.MuiAutocomplete-popper .greyed-out {
  color: gray;
}

.health-ticket-container .ticket-business-status {
  text-align: left;
  line-height: 32px;
}
.health-ticket-container .ticket-arrival-date .MuiSvgIcon-root, 
.health-ticket-container .ticket-business-status .MuiSvgIcon-root {
  vertical-align: middle;
}

.health-ticket-history-records {
  padding: 5px 15px;
}

.ticket-changable-fields {
  padding: 0;
}
.ticket-changable-fields-editing {
  padding: 15px;
  background: #fbfbfb;
  margin-bottom: 15px;
}

.inline-selector {
  width: 100%;
}

.inline-selector a {
  overflow: hidden;
}

.inline-selector .selection {
  margin-right: 6px;
  cursor: pointer;
}

.inline-selector .selection .MuiChip-root {  
  border: 4px dashed transparent;
  cursor: pointer;
  opacity: 0.27;
}

.inline-selector .selection-reccomended .MuiChip-root {  
  border: 4px dashed #666;
  cursor: pointer;
}

.inline-selector .selection-selected .MuiChip-root {  
  border: 4px solid #cdcdcd;
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
}

.inline-selector .selection-selected  .MuiChip-root.priority-indicator-bg-stat {
  border: 4px solid #c32020;
}

.inline-selector .selection-selected  .MuiChip-root.priority-indicator-bg-asap {
  border: 4px solid #b9872b;
}
.inline-selector .selection-selected .MuiChip-root.priority-indicator-bg-urgent {
  border: 4px solid #dfdf0a;
}
.inline-selector .selection-selected .MuiChip-root.priority-indicator-bg-routine {
  border: 4px solid #236023;
}

.link-open-web-external-booking {
  font-weight: bold;
  color: hsl(231, 54%, 40%);
}
.link-open-web-external-booking:hover, .link-open-web-external-booking:active {
  color: #3f51b5;
}

.inline-selector.tickets-list-selector .selection {

}
.inline-selector.tickets-list-selector .selection .MuiChip-root {  
  border: 4px dashed transparent;
  cursor: pointer;
  opacity: 0.8;
  margin-left: 10px;
}

.inline-selector.tickets-list-selector .selection-selected .MuiChip-root {  
  border: 4px solid #cdcdcd;
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
}

.booking-iframe {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.booking-iframe-minmax {
  transition: all 500ms;
}
.booking-iframe-maximized, 
.booking-iframe-maximized.MuiGrid-container {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: white;
  padding-top: 8px;
}
.booking-iframe-maximized .ticket-actions-col {
  padding-right: 15px;
}
.booking-iframe-maximized .booking-iframe {
  height: 100%;
}
.booking-iframe-maximized .health-ticket-booking-iframe-container {
  height: 93%;
}
/* TicketModal styles end */


/* next ticket workflow  */


.next-ticket-dashboard {
  min-height: 60vh;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  padding: 30px;
}

.next-ticket-dashboard .container-loading {
  text-align: center;
}
.next-ticket-dashboard .all-tickets-done {
  text-align: center;
  line-height: 2rem;
  font-size: 2rem;
}
.next-ticket-dashboard .message {
  padding-top: 30px;
}

/* PreviewQuestionnaireResponse styles  */
.preview-questionnaire-table {
  border: 1px solid rgba(224, 224, 224, 1);
}


.preview-questionnaire-table .borderless {
  border-bottom: 0 solid rgba(224, 224, 224, 1);
}
/* PreviewQuestionnaireResponse styles end */



/* Auth / login styles */
.mdl-card--auth-card {
  margin: auto;
  margin-top: 7%;
  border: 1px solid #a2bcd9;
  border-radius: 16px;

  font-size: 16px;
  
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}

.mdl-card--auth-card .mdl-card__title,
.mdl-card--auth-card .mdl-card__supporting-text,
.mdl-card--auth-card .mdl-card__actions {
  padding-left: 20px;
  padding-right: 20px;
}
.mdl-card--auth-card .mdl-card__actions {
  padding: 20px 20px 16px 20px;
}

.mdl-card--auth-card .mdl-card__title {
  padding: 16px 16px 8px 16px;
}
.mdl-card--auth-card .mdl-card__title img {
  margin: 53px auto 0px auto;
  max-width: 180px;
}
.mdl-card.mdl-card--auth-card .mdl-card__supporting-text {
  
  line-height: 18px;
  overflow: hidden;
  padding: 16px;

  padding-left: 20px;
  padding-right: 20px;
  
  text-align: justify;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #232323;
  font-size: 16px;

  text-align: justify;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #232323;
  font-size: 16px;
}
.mdl-card.mdl-card--auth-card .mdl-card__supporting-text p {
  padding: 0;
  letter-spacing: 0;
  margin: 0 0 16px;
  line-height: 24px;
  font-size: 16px;
}
.mdl-card.mdl-card--auth-card .form-user-login {
  margin-top: 36px;
}
.mdl-card.mdl-card--auth-card .mdl-components__warning {
  margin-bottom: 20px;
}

.mdl-card--auth-card.attempting-login {
  opacity: 0.95;
}

.mdl-card--auth-card .container-loading {
  padding: 10px;
  margin-top: 0;
}

.mdl-card--auth-card .mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;

  color: #232323;
  width: 100%;
}
.mdl-card--auth-card input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica","Arial",sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;

  font-size: 24px;
  width: 100%;
  border-bottom-width: 2px;
  text-align: center;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}
.mdl-card--auth-card .mdl-textfield .mdl-textfield__input:focus {
  outline: none;
}
.mdl-card--auth-card .mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
  border-bottom: 2px solid #009688;
}

.mdl-card.mdl-card--auth-card {
  max-width: 480px;
  width: 100%;
}

.mdl-card--auth-card .mdl-card__actions {
  text-align: center;
}
.mdl-card--auth-card .auth-button-login {
  color: #009688;
  font-size: 28px;
  line-height: 38px;
  text-transform: none;
  padding: 8px 16px;
}

.mdl-card--auth-card .mdl-components__warning {
  width: 100%;
  max-width: 100%;
  background-color: #fff9c4;
  padding: 16px 0;
  border-radius: 2px;
  color: #212121;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}

.mdl-card--auth-card .mdl-list {
  list-style: none;
}

.mdl-card--auth-card  .mdl-list__auth-item {
  
  list-style: none;

  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  min-height: 48px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px;
  cursor: default;
  color: rgba(0,0,0,.87);
  overflow: hidden;

  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  padding-bottom: 30px;
  align-items: stretch;
}
.mdl-card--auth-card  .mdl-list__auth-item a {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mdl-card--auth-card  .mdl-list__auth-item .img-wrap {
  max-width: 50px;
  max-height: 50px;
  min-height: 50px;
  min-width: 50px;
  display: inline-flex;
  margin-bottom: 12px;
}
.mdl-card--auth-card  .mdl-list__auth-item img {
  object-fit: contain;
}

.mdl-card--auth-card  .mdl-list__auth-item .mdl-button {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255,255,255,0);

  background: 0 0;
    border: none;
    border-radius: 2px;
    color: #000;
    position: relative;
    height: 36px;
    margin: 0;
    min-width: 64px;
    display: inline-block;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;

  background: rgba(158,158,158,.2);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);

  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 15px;

  height: auto;
  font-size: 12px;
  line-height: 19px;
}

.mdl-card--auth-card .mdl-card__title  {
  text-align: center;
}
.mdl-card--auth-card .mdl-card__title img {
  max-height: 30px;
}

/* Auth / login styles end */


/* Employee list - start */

.select-organization label {
  width: 100%;
  max-width: 330px;
}

.select-organization .MuiFormControl-root, 
.select-organization .MuiInputBase-formControl, 
.select-organization .MuiSelect-select {
  width: 100%;
  max-width: 330px;
}

.employees-table-top {
  text-align: right;
}
.employees-table-top .pull-left {
  float:left;
}
.add-employee-button, .employees-table-top .MuiButtonBase-root {
  float: right;
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
}

/* Employee list - end */


/* Employee form - end */
.employee-form-inline {
  margin: 5px 0;
}
.employee-form-actions-col {
  text-align: right;
}
.externally-managed-badge-col svg {
  vertical-align: middle;
}

.employee-form-body-container .cell-data {
  padding-top: 10px;
  padding-bottom: 10px;
}
.employee-form-body-container .MuiOutlinedInput-input {
  padding: 12px 9px;
}

.employee-form-body-container .MuiAlert-root {
  width: 100%;
}
/* Employee form - end */